<template>
  <div>usecase</div>
</template>

<script>
export default {
    name: 'Usecases',

}
</script>

<style>

</style>